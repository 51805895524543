



























































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { BonusService } from '@/services/bonus-service';
import bonuseUpload from './compoennts/bonuseUpload.vue';
@Component({
  components: {
    bonuseUpload
  }
})
export default class wechatImageText extends Vue {
  //新增编辑表单
  public form: any = {}
  public detailList: any[] = [];
  public labelPosition: String = 'top';
  private tableData: any[] = [];
  public loading: Boolean = false;
  public multipleSelection: any[] = []
  public awardsAdminIdList: any [] = []
  public isHandAddDisable: boolean = true; //加号按钮如果Franchise 校验没通过，不可点击
  @Inject(BonusService) private bonusService!: BonusService;

  public async created(): Promise<void> {
    this.form = {
      mainTitle: '',
      mainAdvertisementContent: '',
      mainSubTitle: '',
      franchise: ''
    };
    this.fetchDetail();
  }
  //详情内容
  public async fetchDetail() {
    let detailForm = await JSON.parse(localStorage.getItem('newContentForm')) || {};
    this.form = detailForm;
    this.fetchList();
    this.franchiseCheck();
  }
  //校验franchise是否有效
  public async franchiseCheck() {
    const res = await this.bonusService.franchiseCheck({
      franchise: this.form.franchise
    });
    this.isHandAddDisable = !res;
  }
  //缓存表单数据
  public saveLocalStorage(val: any) {
    localStorage.setItem('newContentForm', JSON.stringify(this.form));
    localStorage.setItem('addDetailsNewContent',JSON.stringify(val));
  }
  //新增
  public handAdd(){
    this.saveLocalStorage('-1');
    this.$router.push({
        path: '/addDetailsNewContent',
        query: { franchise:  this.form.franchise }
      });
  }
  //修改
  public async handUpdate(row: any){
    this.saveLocalStorage(row);
    this.$router.push({
        path: '/addDetailsNewContent',
        query: { franchise:  this.form.franchise }
      });
  }
  //获取列表数据
  public async fetchList() {
    this.loading = true;
    let res = await JSON.parse(localStorage.getItem('newContentList')) || [];
    this.tableData = res || [];
    this.loading = false;
  }
  //监听删除的列表项
  public handleSelectionChange(val: any) {
    this.multipleSelection = val;
  }
  //删除函数
  private async handleDeleteCommon(rows: any[]): Promise<void> {
    if (!rows.length) {
      this.$message.warning('请选择要删除的数据');
      return;
    }
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });

      let list = await JSON.parse(localStorage.getItem('newContentList')) || [];
      list = list.filter(item => !rows.some(row => item.id === row.id));

      localStorage.setItem('newContentList', JSON.stringify(list));
      await this.fetchList();
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  //批量删除
  public async handMinus() {
    await this.handleDeleteCommon(this.multipleSelection);
  }
  //单个删除
  private async handDelete(row: any): Promise<void> {
    await this.handleDeleteCommon([row]);
  }
  //保存
  public submitForm() : void{
      const addRef = (this.$refs.form as any)
      addRef.validate( async (valid: any) => {
        const param = {...this.form, details:this.tableData.map(item => {
          return {
            awardsAdminId: item.awardsAdminId,
            content: item.content,
            orderBy: item.orderBy,
            showType: item.showType,
            title: item.title,
          }
        })}
        if (valid) {
          const res = await this.bonusService.awardsContentCreate(param);
          if(res){
            this.$message({
              message: '添加成功',
              type: 'success'
            });
            this.$router.push('/awardContent');
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
  }
  //取消
  public async cancel() {
    await this.$confirm('当前信息将会丢失，确认是否取消?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    });
    this.$router.push('/awardContent');
  }

}
